.App {
  --hash-background: #262631;
  --hash-background-darker: #1b1d24;
  --hash-border: #393a3b;
  --hash-blue: #1e77ff;
  --hash-blue-hover: #3d8aff;
  --hash-red: #f51624;
  --hash-off-white: #e6e6e6;

  background: var(--hash-background-darker);
  height: 100%;
}

.App * {
  box-sizing: border-box;
}

input,
textarea,
select {
  padding: 6px 10px;
  font-size: 14px;
  line-height: 18px;
  border: 1px solid var(--hash-border);
  color: white;
  background: rgba(241, 243, 246, 0.1);
  background: #34353a;
  transition: color 0.2s, background-color 0.2s, box-shadow 0.2s, border 0.2s;
}

input::placeholder,
textarea::placeholder,
select::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

input:hover,
textarea:hover,
select:hover {
  background-color: rgba(201, 213, 230, 0.144);
}

input:focus,
textarea:focus,
select:focus {
  background-color: rgba(201, 213, 230, 0.144);
  outline: none;
  border-color: #888;
}

button {
  position: relative;
  cursor: pointer;
  user-select: none;
  background: var(--hash-blue);
  border-radius: 2px;
  font-size: 13px;
  font-weight: 700;
  color: white;
  transition: all 0.2s;
  padding: 6px 12px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

button:hover {
  background-color: var(--hash-blue-hover);
  opacity: 0.95;
}

button:focus {
  outline: none;
}

.red-text {
  color: var(--hash-red);
}

.Panel {
  width: 240px;
  max-height: 500px;
  overflow: auto;
  background: var(--hash-background-darker);
  color: #d4d4d4;
  height: fit-content;
  padding: 15px;
  text-transform: uppercase;
  border: 1px solid var(--hash-border);
  font-family: Arial, Helvetica, sans-serif;
}

.Panel::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background: transparent;
}

.Panel::-webkit-scrollbar-thumb {
  background: grey;
}

.Panel * {
  box-sizing: border-box;
}

.Panel h3 {
  font-size: 13px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
  user-select: none;
}

.Panel h3 a {
  color: white;
  text-decoration: none;
  margin-left: 4px;
}

.Panel h3 a:hover {
  text-decoration: underline;
}
