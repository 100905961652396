.SettingsPanel {
  position: absolute;
  top: 60px;
  right: 20px;
}

.PropertyInput__resources button {
  margin-top: 12px;
}

.PropertyInput__resource {
  display: flex;
}

.PropertyInput__resource:not(:last-child) {
  margin-bottom: 12px;
}

.PropertyInput__resource .PropertyInput__input--number {
  width: 60px !important;
  margin-left: 10px;
}
