.PropertiesPanel {
  position: absolute;
  top: 70px;
  right: 20px;
  z-index: 2;
}

.PropertyInput {
  width: 100%;
}

.PropertyInput__label {
  display: block;
  font-size: 12px;
  font-weight: 700;
  padding-bottom: 4px;
}

.PropertyInput__label .red-text {
  margin-left: 4px;
  font-size: 11px;
}

.PropertyInput--boolean {
  display: flex;
  align-items: center;
}

.PropertyInput--boolean label {
  padding-bottom: 0;
  margin-right: 5px;
}

.PropertyInput:not(:last-child) {
  margin-bottom: 15px;
}

.PropertyInput:not(.PropertyInput--boolean) input,
textarea {
  width: 100%;
}

textarea {
  height: 120px;
}

select.PropertyInput__input {
  width: 100%;
}

select.PropertyInput__input option {
  color: initial;
}
