.FlowChart {
  height: 100%;
  display: flex;
  justify-content: space-between;
  background: var(--hash-background-darker);
  margin-bottom: 30px;
}

.FlowChart__canvas {
  width: 100%;
}

.FlowChart__sidebar {
  top: 20px;
  right: 20px;
  margin-bottom: 25px;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.FlowChart__error {
  color: white;
  padding: 15px;
  font-size: 14px;
  position: absolute;
  left: 40vw;
  z-index: 10;
  max-width: 40vw;
  top: 15px;
  background: #10101b;
  border: 1px solid var(--hash-border);
  border-radius: 8px;
  font-weight: 600;
}

.IconSettings {
  display: flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
}

.IconSettings svg {
  fill: #d4d4d4;
}
